<template>
  <div style="width: 100%">
    <h5>Mapa do Evento</h5>
    <v-card outlined class="rounded-lg">
      <table-selector
        class="rounded-lg elevation-0"
        v-if="tableMap"
        :table-map="tableMap"
        :party="party"
        :loading="loading"
        :value="null"
        @input="goToTicketGroup"
      />
    </v-card>
  </div>
</template>

<script>
import TableSelector from "../global/partyTable/TableSelector.vue";

export default {
  components: { TableSelector },
  computed: {},
  methods: {
    async goToTicketGroup(val) {
      if (!this.ticketGroups) return;
      const ticketGroup = this.ticketGroups.find(
        (tg) => tg.tableGroupId === val.group.id
      );
      if (ticketGroup) {
        const el = document.getElementById(`ticket-group-selector`);
        await this.$vuetify.goTo(el, { duration: 500, offset: 50 });
        this.$root.$emit("select-ticket-group", ticketGroup.id);
        // await new Promise((resolve) => setTimeout(resolve, 200));
        // await this.$vuetify.goTo(el, { duration: 500, offset: 150 });
      }
    },
  },
  props: {
    tableMap: {
      type: Object,
    },
    party: {
      type: Object,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    ticketGroups: {
      type: Array | Boolean,
      default: () => [],
    },
  },
};
</script>

<style></style>
