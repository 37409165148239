<template>
  <div>
    <div>
      <v-img
        :src="organization.cover"
        :aspect-ratio="21 / 9"
        class="rounded-xl"
        max-height="260px"
      />
    </div>
    <v-card
      class="pa-2 ml-md-8 ml-lg-12 ml-4"
      elevation="0"
      style="
        border-top-left-radius: 75px !important;
        border-bottom-left-radius: 75px !important;
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 24px !important;
        margin-top: -50px !important;
      "
    >
      <div class="d-flex align-center justify-space-between gap-4">
        <div class="d-flex align-center flex-grow-1 gap-4">
          <base-avatar
            :src="organization.photo"
            :seed="organization.id"
            :size="75"
            avatarStyle="shapes"
            color="secondary lighten-2"
          />
          <div>
            <h5 class="font-weight-bold mb-0">
              {{ organization.name }}
            </h5>
            <a
              v-if="organization.phone && organization.ddi"
              class="font-weight-light"
              @click="openWhatsapp"
            >
              {{ organization.phone }}
            </a>
          </div>
        </div>
        <div class="text-end">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                :href="`mailTo:${organization.email}`"
                icon
                target="_blank"
              >
                <v-icon>mdi-email</v-icon>
              </v-btn>
            </template>
            <span>{{ organization.email }}</span>
          </v-tooltip>
          <v-tooltip
            top
            v-for="(sn, i) in organization.socialNetworks"
            :key="i"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :href="socialNetworksItens[sn.type].urlFormatter(sn.url)"
                icon
                v-bind="attrs"
                v-on="on"
                target="_blank"
              >
                <v-icon>{{ socialNetworksItens[sn.type].icon }}</v-icon>
              </v-btn>
            </template>
            <span>{{ socialNetworksItens[sn.type].prefix }}{{ sn.url }}</span>
          </v-tooltip>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  data: () => ({
    socialNetworksItens: {
      ig: {
        id: "ig",
        name: "Instagram",
        icon: "mdi-instagram",
        urlFormatter: (url) => `https://instagram.com/${url}`,
        prefix: "@",
        placeholder: "Digite o nome de usuário",
      },
      fb: {
        id: "fb",
        name: "Facebook",
        icon: "mdi-facebook",
        urlFormatter: (url) => `https://facebook.com/${url}`,
        prefix: "@",
        placeholder: "Digite o nome de usuário",
      },
      tw: {
        id: "tw",
        name: "Twitter",
        icon: "mdi-twitter",
        urlFormatter: (url) => `https://twitter.com/${url}`,
        prefix: "@",
        placeholder: "Digite o nome de usuário",
      },
      yt: {
        id: "yt",
        name: "Youtube",
        icon: "mdi-youtube",
        urlFormatter: (url) => url,
        prefix: "",
        placeholder: "Digite o link do canal",
      },
      tt: {
        id: "tt",
        name: "TikTok",
        icon: null,
        urlFormatter: (url) => `https://tiktok.com/@${url}`,
        prefix: "@",
        placeholder: "Digite o nome de usuário",
      },
    },
  }),
  methods: {
    openWhatsapp() {
      const phone = this.organization.phone.replace(/\D/g, "");
      const ddi = this.organization.ddi;
      window.open(`https://wa.me/${ddi}${phone}`, "_blank");
    },
  },
  props: {
    organization: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style></style>
