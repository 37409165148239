import { unzip, strFromU8 } from 'fflate';
import * as THREE from "three";


export default async (url, resp) => {
  const contents = await fetch(url).then((r) => r.arrayBuffer());
  unzip(new Uint8Array(contents), async (err, zip) => {
    if (err) throw err;
    // Poly

    if (zip['model.obj'] && zip['materials.mtl']) {

      const { MTLLoader } = await import('three/examples/jsm/loaders/MTLLoader.js');
      const { OBJLoader } = await import('three/examples/jsm/loaders/OBJLoader.js');

      const materials = new MTLLoader().parse(strFromU8(zip['materials.mtl']));
      const object = new OBJLoader().setMaterials(materials).parse(strFromU8(zip['model.obj']));
      resp(object);

    }

    //

    for (const path in zip) {

      const file = zip[path];

      const manager = new THREE.LoadingManager();
      manager.setURLModifier(function (url) {

        const file = zip[url];

        if (file) {
          const blob = new Blob([file.buffer], { type: 'application/octet-stream' });
          return URL.createObjectURL(blob);
        }

        return url;

      });

      const extension = path.split('.').pop().toLowerCase();

      switch (extension) {

        case 'fbx':
          {
            const { FBXLoader } = await import('three/examples/jsm/loaders/FBXLoader.js');

            const loader = new FBXLoader(manager);
            const object = loader.parse(file.buffer);

            resp(object);

            break;

          }

        case 'glb':

          {

            const { DRACOLoader } = await import('three/examples/jsm/loaders/DRACOLoader.js');
            const { GLTFLoader } = await import('three/examples/jsm/loaders/GLTFLoader.js');

            const dracoLoader = new DRACOLoader();
            // dracoLoader.setDecoderPath('../examples/js/libs/draco/gltf/');

            const loader = new GLTFLoader();
            loader.setDRACOLoader(dracoLoader);

            loader.parse(file.buffer, '', function (result) {
              result.scene.animations.push(...result.animations);

              resp(result);
            });

            break;

          }

        case 'gltf':

          {

            const { DRACOLoader } = await import('three/examples/jsm/loaders/DRACOLoader.js');
            const { GLTFLoader } = await import('three/examples/jsm/loaders/GLTFLoader.js');

            const dracoLoader = new DRACOLoader();
            dracoLoader.setDecoderPath('../examples/js/libs/draco/gltf/');

            const loader = new GLTFLoader(manager);
            loader.setDRACOLoader(dracoLoader);
            loader.parse(strFromU8(file), '', function (result) {
              result.scene.animations.push(...result.animations);
              resp(result);
            });

            break;

          }

      }

    }
  });

}
