var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{attrs:{"outlined":"","rounded":"lg"}},[_c('v-card-text',[(_vm.pendingPayment)?_c('v-btn',{staticClass:"float-right ml-1",attrs:{"to":{
          name: 'app.payment.details',
          params: { id: _vm.pendingPayment.Payment.id },
          hash: '#pay',
        },"color":"error"}},[_vm._v(" Pagar ")]):_vm._e(),_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"mb-1 font-weight-bold text-18"},[_vm._v(" Meu"+_vm._s(_vm.tickets.length != 1 ? "s" : "")+" Ingresso"+_vm._s(_vm.tickets.length != 1 ? "s" : "")+" ")]),_c('span',{staticClass:"mb-0 text-16"},[_vm._v(_vm._s(_vm.statusMap[_vm.status].text))])]),_c('v-data-iterator',{attrs:{"items":_vm.tickets,"item-key":"id","page":_vm.page,"items-per-page":6,"hide-default-footer":true},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"default",fn:function({ items }){return [(!_vm.pendingPayment)?_c('v-row',{staticClass:"mx-0",attrs:{"align-content":"stretch","dense":""}},_vm._l((items),function(ticket,i){return _c('v-col',{key:i,attrs:{"cols":"12","md":"6","lg":"4"}},[_c('router-link',{attrs:{"to":{
                  name: 'app.ticket.details',
                  params: { id: ticket.id },
                }}},[_c('v-alert',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"mb-0 pr-2 h-full",staticStyle:{"cursor":"pointer"},attrs:{"border":ticket.TicketEntry ? undefined : 'left',"text":"","color":_vm.ticketStatusLabel[ticket.Payment.status].color,"dense":""}},[_c('div',{staticClass:"d-flex gap-3 pt-xl-0"},[(_vm.status != 'pending' && _vm.tickets.length > 1)?_c('p',{staticClass:"mb-0 text-23 font-weight-black"},[_vm._v(" "+_vm._s(i + 1 + (_vm.page * 6 - 6))+". ")]):_vm._e(),_c('div',{staticClass:"flex-grow-1"},[_c('v-chip',{staticClass:"float-right",staticStyle:{"cursor":"pointer"},attrs:{"label":"","x-small":"","color":_vm.ticketStatusLabel[ticket.Payment.status].color}},[_c('v-icon',{attrs:{"x-small":"","left":""}},[_vm._v(" "+_vm._s(_vm.ticketStatusLabel[ticket.Payment.status].icon)+" ")]),_vm._v(" "+_vm._s(_vm.ticketStatusLabel[ticket.Payment.status].text)+" ")],1),_c('p',{staticClass:"mb-0 text-14 lh-1 font-weight-bold"},[_vm._v(" "+_vm._s(ticket.TicketBlock.TicketGroup.name)+" ")]),_c('b',{staticClass:"mb-0 text-12 lh-1 font-weight-medium"},[_vm._v(" "+_vm._s(ticket.TicketBlock.name)+" ")])],1),(ticket.TicketEntry)?_c('v-chip',{staticClass:"ml-1",attrs:{"label":"","x-small":""}},[_vm._v(" Usado ")]):_vm._e()],1)])],1)],1)}),1):_vm._e()]}},(_vm.tickets.length > 6)?{key:"footer",fn:function(){return [_c('div',{staticClass:"d-flex align-end justify-content-center mx-1 mt-2"},[_c('span',{staticClass:"disabled--text lh-1"},[_vm._v(" Página "+_vm._s(_vm.page)+" de "+_vm._s(_vm.numberOfPages)+" ")]),_c('v-spacer'),_c('v-btn',{staticClass:"mr-1",attrs:{"icon":"","small":"","depressed":"","disabled":_vm.page == 1},on:{"click":_vm.formerPage}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_c('v-btn',{staticClass:"ml-1",attrs:{"icon":"","small":"","depressed":"","disabled":_vm.page == _vm.numberOfPages},on:{"click":_vm.nextPage}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)]},proxy:true}:null],null,true)})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }