<template>
  <div ref="bg">
    <div class="d-flex flex-column pb-16 mb-6" style="gap: 1rem">
      <!-- Capa e detalhes -->
      <div>
        <party-cover-video :party="data?.party" />

        <!-- Fast links -->
        <v-chip-group column>
          <v-chip
            v-for="fastLink in fastLinks"
            :key="fastLink.to"
            small
            outlined
            @click="goTo(fastLink)"
          >
            <v-icon v-if="fastLink.icon" left x-small>
              {{ fastLink.icon }}
            </v-icon>

            {{ fastLink.label }}
          </v-chip>
        </v-chip-group>
      </div>

      <v-card elevation="0" rounded="lg" outlined>
        <v-card-title class="d-block">
          <div>
            <v-chip
              v-if="!loading"
              label
              small
              @click="goTo('policies')"
              :color="data?.party.ageGroup == 0 ? 'success' : 'info'"
              class="float-right ml-2 mt-1"
            >
              {{
                data?.party.ageGroup == 0 ? "Livre" : `+${data?.party.ageGroup}`
              }}
              {{ data?.party.ageWithParent ? "*" : "" }}
            </v-chip>
            <h3
              class="mb-0 flex-grow-1"
              style="word-break: normal; line-height: 1.2"
            >
              <v-skeleton-loader v-if="loading" type="heading" height="30" />
              <span v-else>{{ data?.party.name }}</span>
            </h3>
            <h6
              v-if="loading || data?.party?.subtitle"
              class="mb-0 flex-grow-1 text--secondary"
              style="word-break: normal; line-height: 1.2"
            >
              <v-skeleton-loader
                v-if="loading"
                type="heading"
                height="17"
                width="60%"
              />
              <span v-else>{{ data?.party.subtitle }}</span>
            </h6>
          </div>
        </v-card-title>
        <v-card-text>
          <div class="d-flex flex-column gap-1">
            <div class="d-flex align-center">
              <v-icon small left>mdi-calendar-start</v-icon>
              <v-skeleton-loader
                class="flex-grow-1 mb-0 py-1"
                v-if="loading"
                type="heading"
                height="22"
              />
              <span v-else>{{ data?.party.date | formatDate }} até</span>
            </div>
            <div
              v-if="data?.party?.endDate || loading"
              class="d-flex align-center"
            >
              <v-icon small left>mdi-calendar-end</v-icon>
              <v-skeleton-loader
                class="flex-grow-1 mb-0 py-1"
                v-if="loading"
                type="heading"
                height="22"
              />
              <span v-else>{{ data?.party.endDate | formatDate }}</span>
            </div>
          </div>
          <div v-if="data?.party?.description" class="mt-3">
            <h6 class="mb-0">Descrição</h6>
            <party-description-card :party="data?.party" />
          </div>
        </v-card-text>
      </v-card>

      <template v-if="status === true && !loading">
        <my-ticket
          v-if="data?.tickets && data?.tickets.length > 0"
          :tickets="data?.tickets"
        />
        <template v-if="data?.ticketGroups">
          <ticket-group-selector
            :tickets="data?.tickets"
            :ticket-groups="data?.ticketGroups"
            :party="data?.party"
            :code="code"
            :periods="data?.periods"
            :fees="data?.fees"
            :tableMap="data?.tableMap"
            :seller="data?.seller"
            @refresh="$emit('refresh', false)"
          />
          <party-sellers-list
            v-if="data?.sellers && data?.sellers.length"
            :party="data?.party"
            :sellers="data?.sellers"
            :loading="loading"
          />
        </template>
        <v-alert v-else color="warning" text class="mb-0" border="bottom">
          <template
            v-if="
              !!data?.party.maxTickets &&
              data?.tickets.length >= data?.party.maxTickets
            "
          >
            <b>
              Você possui a quantidade máxima de ingressos para esse evento.
            </b>
            {{ data?.party.maxTickets }} ingressos por pessoa.
          </template>
          <template v-else>
            <b>Você possui uma compra pendente.</b> Finalize-a para comprar mais
            ingressos.
          </template>
        </v-alert>
      </template>
      <v-alert
        v-else-if="!loading"
        text
        type="warning"
        class="mb-0"
        :icon="status == 'finished' ? 'mdi-calendar-remove' : undefined"
      >
        <b v-if="status == 'finished'">Este evento já terminou</b>
        <b v-else>Esse evento está indisponível</b>
      </v-alert>

      <template v-if="data?.party?.imageMap">
        <h5>Mapa</h5>
        <v-img
          alt="Mapa"
          :src="data?.party?.imageMap"
          class="rounded-lg"
          contain
          height="350px"
        />
      </template>

      <PartyTableMap
        ref="tableMap"
        v-if="data?.tableMap && status === true && !loading"
        :table-map="data?.tableMap"
        :party="data?.party"
        :loading="loading"
        :ticket-groups="data?.ticketGroups"
      />

      <party-map ref="address" :party="data?.party" :loading="loading" />

      <spotify-embed v-if="data?.party.spotifyId" :id="data?.party.spotifyId" />

      <organization-card
        ref="organization"
        :organization="data?.organization"
        :loading="loading"
      />

      <GLTFMapViewer
        v-if="has3DMap"
        ref="3dMap"
        :map="data?.map"
        :loading="loading"
      />

      <party-gallery
        ref="gallery"
        v-if="hasGallery"
        :gallery="data?.gallery"
        :loading="loading"
      />
      <attractions-list
        ref="attractions"
        v-if="hasAttractions"
        :attractions="data?.attractions"
        :periods="data?.periods"
        :tickets="data?.tickets"
        :loading="loading"
      />

      <party-policies ref="policies" :party="data?.party" :loading="loading" />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import PartyStatus from "@/utils/partyStatus";

import PartyDescriptionCard from "@/components/shop/PartyDescriptionCard.vue";
import PartyGallery from "@/components/shop/PartyGallery.vue";
import MyTicket from "@/components/shop/MyTicket.vue";
import TicketGroupSelector from "@/components/shop/TicketGroupSelector.vue";
import AttractionsList from "@/components/global/party/AttractionsList.vue";
import OrganizationCard from "@/components/shop/OrganizationCard.vue";
import PartyMap from "../../components/shop/PartyMap.vue";
import PartyTableMap from "../../components/shop/PartyTableMap.vue";

import PartyPolicies from "../../components/shop/PartyPolicies.vue";
import GLTFMapViewer from "../../components/shop/GLTFMapViewer.vue";
import PartyCoverVideo from "../../components/shop/PartyCoverVideo.vue";
import PartySellersList from "../../components/shop/PartySellersList.vue";
import SpotifyEmbed from "../../components/global/SpotifyEmbed.vue";

export default {
  components: {
    PartyDescriptionCard,
    PartyGallery,
    MyTicket,
    TicketGroupSelector,
    AttractionsList,
    OrganizationCard,
    PartyMap,
    PartyTableMap,
    PartyPolicies,
    GLTFMapViewer,
    PartyCoverVideo,
    PartySellersList,
    SpotifyEmbed,
  },
  data() {
    return {
      fLinks: [
        {
          icon: "mdi-share-variant",
          label: "Compartilhar",
          func: this.share,
          if: "canShare",
        },
        {
          label: "Mapa",
          to: "tableMap",
          if: "hasTableMap",
        },
        {
          label: "Local",
          to: "address",
          if: "hasAddress",
        },
        {
          label: "Organização",
          to: "organization",
        },
        {
          label: "Mapa",
          to: "3dMap",
          if: "has3DMap",
        },
        {
          label: "Galeria",
          to: "gallery",
          if: "hasGallery",
        },
        {
          label: "Cronograma",
          to: "attractions",
          if: "hasAttractions",
        },
        {
          label: "Políticas",
          to: "policies",
        },
      ],
    };
  },
  methods: {
    share() {
      navigator.share({
        title: this.data?.party.name,
        text: this.data?.party.name,
        url: window.location.href,
      });
    },
    goTo({ to, func }) {
      if (func) return func();

      const el = this.$refs[to];
      this.$vuetify.goTo(el || to, {
        offset: 30,
        behavior: "smooth",
      });
    },
    setBg() {
      this.$root.$emit("setImgBg", this.data?.party.cover);
    },
  },
  mounted() {
    this.setBg();
  },
  destroyed() {
    this.$root.$emit("setImgBg", null);
  },
  watch: {
    "data.party.cover": {
      handler() {
        this.setBg();
      },
    },
  },
  computed: {
    status() {
      if (!this.data?.party) return false;
      const status = new PartyStatus(this.data?.party);
      return status.isActive || status.status;
    },
    canShare() {
      return navigator.share && this.isPublic;
    },
    isPublic() {
      return !this.data?.party?.private;
    },
    userHasTicket() {
      return this.data?.tickets && this.data?.tickets.length > 0;
    },
    hasGallery() {
      return this.data?.gallery && this.data?.gallery.length > 0;
    },
    hasAttractions() {
      return this.data?.attractions && this.data?.attractions.length > 0;
    },
    hasAddress() {
      return !!this.data?.party.Address;
    },
    hasTableMap() {
      return this.data?.tableMap;
    },
    has3DMap() {
      return this.data?.map;
    },
    fastLinks() {
      return this.fLinks.filter((link) => {
        if (link.if) return this[link.if];
        return true;
      });
    },
  },
  filters: {
    formatDate(date) {
      const momentDate = moment(date);
      var formatPattern = "DD [de] MMMM";

      if (momentDate.year() != moment().year()) formatPattern += " [de] YYYY";
      else if (momentDate.isBefore(moment(), "day"))
        formatPattern += " [de] YYYY";

      formatPattern += " [às] HH[h]mm";

      return momentDate.format(formatPattern);
    },
  },
  props: {
    data: {
      type: Object,
    },
    code: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
