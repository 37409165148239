<template>
  <description-wrap :description="party.description" />
</template>

<script>
import DescriptionWrap from '../global/DescriptionWrap.vue';

export default {
  components: { DescriptionWrap },
  props: {
    party: {
      type: Object,
      required: true,
    },
  }
}
</script>

<style></style>