<template>
  <div class="pa-4 py-6">
    <cols-organizer :cols="1" :sm="2" :md="3" :items="organization.Party || []">
      <template v-slot="{ item: party }">
        <party-item :party="party" elevation="2" outlined />
      </template>
    </cols-organizer>
    <div class="text-center mt-4" v-if="!organization.Party.length">
      <v-avatar color="grey lighten-4" size="50">
        <v-icon x-large class="display-1 grey darken-1"> mdi-calendar </v-icon>
      </v-avatar>
      <p class="text--disabled mt-2">Nenhum evento encontrado</p>
    </div>

    <v-alert v-if="!isAuthenticated" prominent type="info" class="mt-8" text>
      <div class="d-flex flex-wrap justify-space-between align-center">
        <div>
          <b>Não encontrou o evento que procurava?</b>
          <br />
          Tente entrar na sua conta para ver evento que você foi convidado.
        </div>
        <v-btn color="primary" @click="login">entrar</v-btn>
      </div>
    </v-alert>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ColsOrganizer from "@/components/global/ColsOrganizer.vue";
import PartyItem from "@/components/shop/PartyItem.vue";
export default {
  components: { PartyItem, ColsOrganizer },
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
  },
  methods: {
    login() {
      this.$router.push({
        name: "sign-in",
        query: { redirect: this.$route.fullPath },
      });
    },
  },
  props: {
    organization: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style></style>
