var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"white--text",style:({
    borderRadius: '10px',
    border: `3px solid ${_vm.membership.backgroundColor || '#000'}`,
    boxShadow: `0 0 10px  ${_vm.membership.backgroundColor || 'rgba(0,0,0,.2)'}`,
    background: `url('${_vm.membership.backgroundImg}'), ${
      _vm.membership.backgroundColor || '#000'
    }`,
    backgroundSize: 'cover',
  }),on:{"click":() => {}}},[_c('div',{staticClass:"d-flex flex-column justify-space-between h-full pa-4 gap-7",staticStyle:{"background":"rgba(0, 0, 0, 0.8)"}},[_c('div',{staticClass:"d-flex gap-4"},[_c('h3',{staticClass:"white--text mb-0"},[_vm._v(_vm._s(_vm.membership.name))])]),_c('div',{staticClass:"d-flex flex-column gap-2"},_vm._l((_vm.benefits),function(benefit,i){return _c('div',{key:i,staticClass:"d-flex gap-4"},[_c('v-icon',{attrs:{"color":"yellow"}},[_vm._v("mdi-star")]),_c('span',[_vm._v(_vm._s(benefit))])],1)}),0),_c('div',{staticClass:"d-flex flex-column"},[_c('h6',{staticClass:"white--text mb-0"},[_vm._v(" A partir de "),(_vm.priceStartIn.validationType == 'PERIOD')?[_c('strong',[_vm._v(" "+_vm._s(_vm._f("currency")((_vm.priceStartIn.price / (_vm.priceStartIn.duration * _vm.periodMultiply[_vm.priceStartIn.durationType]))))+" por mês ")])]:_c('strong',[_vm._v(_vm._s(_vm._f("currency")(_vm.priceStartIn.price,true))+" ")])],2),(_vm.priceStartIn.validationType == 'PERIOD')?_c('p',{staticClass:"mb-0"},[_vm._v(" Plano "+_vm._s(_vm._f("membershipDuration")(_vm.priceStartIn))+" ")]):_c('p',{staticClass:"mb-0"},[_vm._v(" Plano valido até "+_vm._s(_vm._f("date")(_vm.priceStartIn.endDate))+" ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }