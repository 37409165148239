<template>
  <v-card
    class="white--text"
    @click="() => {}"
    :style="{
      borderRadius: '10px',
      border: `3px solid ${membership.backgroundColor || '#000'}`,
      boxShadow: `0 0 10px  ${membership.backgroundColor || 'rgba(0,0,0,.2)'}`,
      background: `url('${membership.backgroundImg}'), ${
        membership.backgroundColor || '#000'
      }`,
      backgroundSize: 'cover',
    }"
  >
    <div
      class="d-flex flex-column justify-space-between h-full pa-4 gap-7"
      style="background: rgba(0, 0, 0, 0.8)"
    >
      <div class="d-flex gap-4">
        <h3 class="white--text mb-0">{{ membership.name }}</h3>
      </div>
      <div class="d-flex flex-column gap-2">
        <div class="d-flex gap-4" v-for="(benefit, i) in benefits" :key="i">
          <v-icon color="yellow">mdi-star</v-icon>
          <span>{{ benefit }}</span>
        </div>
      </div>
      <div class="d-flex flex-column">
        <h6 class="white--text mb-0">
          A partir de
          <template v-if="priceStartIn.validationType == 'PERIOD'">
            <strong>
              {{
                (priceStartIn.price /
                  (priceStartIn.duration *
                    periodMultiply[priceStartIn.durationType]))
                  | currency
              }}
              por mês
            </strong>
          </template>
          <strong v-else>{{ priceStartIn.price | currency(true) }} </strong>
        </h6>
        <p class="mb-0" v-if="priceStartIn.validationType == 'PERIOD'">
          Plano {{ priceStartIn | membershipDuration }}
        </p>
        <p class="mb-0" v-else>
          Plano valido até {{ priceStartIn.endDate | date }}
        </p>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    periodMultiply: {
      MONTH: 1,
      YEAR: 12,
      DAYS: 1 / 30,
    },
  }),
  computed: {
    benefits() {
      return this.membership.description.split("\n").filter((b) => b);
    },
    priceStartIn() {
      const { periodMultiply } = this;
      const period = this.membership.Plans.filter(
        (p) => p.validationType === "PERIOD"
      );
      if (period.length) {
        const periodMin = period.reduce(
          (acc, curr) => {
            const month = curr.duration * periodMultiply[curr.durationType];
            const price = curr.price / month;
            const monthAcc = acc.duration * periodMultiply[acc.durationType];
            const priceAcc = acc.price / monthAcc;
            return price < priceAcc ? curr : acc;
          },
          { price: Infinity, durationType: "MONTH", duration: 1 }
        );
        return periodMin;
      }
      return this.membership.Plans.reduce(
        (acc, curr) => {
          return curr.price < acc.price ? curr : acc;
        },
        { price: Infinity }
      );
    },
  },
  props: {
    membership: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style></style>
