<template>
  <div>
    <v-alert type="error" class="my-2" text border="bottom">
      <div class="font-weight-bold">{{ type.title }}</div>
      <div>{{ type.message }}</div>
    </v-alert>

    <!-- alert login -->
    <v-alert
      v-if="!isAuthenticated && type.showLogin"
      type="info"
      text
      border="bottom"
    >
      <div class="d-flex align-center justify-space-between">
        <div class="font-weight-bold">
          Entre com sua conta para tentar acessar o evento
        </div>
        <div>
          <v-btn color="primary" @click="login"> Entrar </v-btn>
        </div>
      </div>
    </v-alert>

    <!-- <div v-if="type.showOrganization && organization">
      <organization-card :organization="organization" />
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import OrganizationCard from "../../components/shop/OrganizationCard.vue";

export default {
  components: { OrganizationCard },
  data: () => ({
    types: {
      "not-found": {
        title: "Evento não encontrado",
        message: "O evento que você está tentando acessar não existe.",
        showLogin: false,
        showOrganization: true,
      },
      private: {
        title: "Evento Privado",
        message: "Este evento é privado e não pode ser acessado diretamente.",
        showLogin: true,
      },
      default: {
        title: "Erro",
        message: "Ocorreu um erro ao carregar o evento.",
        showLogin: false,
        showOrganization: false,
      },
    },
  }),
  methods: {
    login() {
      this.$router.push({
        name: "sign-in",
        query: { redirect: window.location.pathname },
      });
    },
  },

  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
    type() {
      return this.types[this.error.error.type] || this.types.default;
    },
    organization() {
      return this.error.error?.organization;
    },
  },

  props: {
    error: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
