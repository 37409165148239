<template>
  <v-dialog v-model="dialog" max-width="500" @click:outside="close">
    <v-card>
      <v-card-text class="pa-6">
        <sign-in-form :redirect="false" @success="close(true)" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import SignInForm from "../../global/SignInForm.vue";
export default {
  components: { SignInForm },
  data: () => ({
    dialog: false,
    data: null,
  }),
  methods: {
    open(data) {
      this.data = data;
      this.dialog = true;
    },
    close(success = false) {
      if (success) this.$emit("success", this.data);
      this.dialog = false;
      this.data = false;
    },
  },
  mounted() {
    this.$root.$on("require-sign-in", this.open);
  },
};
</script>

<style></style>
