<template>
  <div>
    <h5 class="mb-0">Galeria</h5>
    <gallery
      @change="view"
      :gallery="gallery.slice(0, 16)"
      :loading="loading"
      :fixCols="3"
    >
      <template v-slot:actions="{ image }">
        <div>
          <v-alert
            dense
            color="warning"
            v-if="inInstagram"
            class="mx-2 mb-0 px-2"
          >
            <div class="d-flex align-center gap-2 white--text">
              <v-icon color="white">mdi-exclamation</v-icon>
              <small>
                O Instagram não permite o download de imagens. Para baixar, abra
                a página em um navegador.
              </small>
            </div>
          </v-alert>
          <div v-else class="d-flex align-center justify-center w-100 gap-2">
            <v-btn
              v-if="navigatorShare"
              color="primary"
              small
              @click="shareImage(image)"
              :disabled="!!loadingFileDownload || inInstagram"
              :loading="loadingFileDownload === 'share'"
            >
              <v-icon small>mdi-share-variant</v-icon> Compartilhar
            </v-btn>
            <v-btn
              color="primary"
              small
              @click.stop="download(image)"
              :disabled="!!loadingFileDownload || inInstagram"
              :loading="loadingFileDownload === 'download'"
            >
              <v-icon small>mdi-download</v-icon> Salvar
            </v-btn>
          </div>
        </div>
      </template>
    </gallery>
    <v-btn text block v-if="gallery.length > 16" @click="openFullGallery">
      Ver todas fotos
    </v-btn>
  </div>
</template>

<script>
import Gallery from "../global/Gallery.vue";
import GALLERY from "@/services/app/gallery.js";
export default {
  components: { Gallery },
  data: () => ({
    currentImage: null,
    loading: false,
    notified: [],
    loadingFileDownload: false,
    inInstagram: false,
    navigatorShare: !!navigator.share,
  }),
  methods: {
    openFullGallery() {
      this.$router.push({
        name: "shop.party.gallery",
        params: { ...this.$route.params },
      });
    },
    async view(image) {
      if (this.notified.includes(image.id)) return;
      try {
        await GALLERY.view(image.id);
        this.notified.push(image.id);
      } catch (e) {
        console.log(e);
      }
    },
    async downloadImage({ id, url }) {
      const response = await fetch(url);
      const blob = await response.blob();
      try {
        await GALLERY.download(id).catch((e) => {});
      } catch (e) {
        console.log(e);
      }
      return blob;
    },
    fileName(image, blob) {
      const tinyId = image.id.toString().padStart(8, "0");
      return `${tinyId}.${blob.type.split("/")[1]}`;
    },
    async shareImage(image) {
      try {
        this.loadingFileDownload = "share";
        if (navigator.share) {
          const blob = await this.downloadImage(image);
          const file = new File([blob], this.fileName(image, blob), {
            type: blob.type,
          });
          navigator.share({
            title: this.party.name,
            text: `Olha essa foto do evento *${this.party.name}*!`,
            files: [file],
          });
        }
      } catch (error) {
        this.error = error.message || "Erro ao baixar a foto";
      } finally {
        this.loadingFileDownload = false;
      }
    },
    async download(image) {
      try {
        this.loadingFileDownload = "download";

        const blob = await this.downloadImage(image);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");

        a.href = url;
        a.target = "_blank";
        a.download = this.fileName(image, blob);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } catch (error) {
        this.error = error.message || "Erro ao baixar a foto";
      } finally {
        this.loadingFileDownload = false;
      }
    },
  },
  mounted() {
    this.inInstagram = navigator.userAgent.includes("Instagram");
  },
  computed: {},
  props: {
    gallery: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style></style>
