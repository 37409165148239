<template>
  <v-row no-gutters>
    <v-col cols="12" md="8" class="px-3" style="height: 300px">
      <div v-if="!loaded || loading" style="position: relative">
        <v-img height="300px" class="rounded" :src="defaultPosition.url" />
        <div
          style="
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.5);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1;
          "
        >
          <v-progress-circular
            v-if="loading"
            :size="70"
            :width="7"
            color="primary"
            indeterminate
          ></v-progress-circular>

          <v-btn v-else @click="startLoad"> Carregar Mapa 3D </v-btn>
        </div>
      </div>
      <GLTFViewer
        v-show="loaded"
        class="rounded"
        style="height: 300px"
        ref="gltf"
        :options="options"
        v-bind:loading.sync="loading"
        @loaded="onLoaded"
      ></GLTFViewer>
    </v-col>
    <v-col cols="12" md="4" class="px-3" style="max-height;: 300px">
      <template v-if="$vuetify.breakpoint.mdAndUp">
        <v-list>
          <v-list-item-group v-model="currentPosition" mandatory color="indigo">
            <v-list-item v-for="(position, i) in positions" :key="i">
              <v-list-item-content>
                <v-list-item-title v-text="position.name"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </template>
      <template v-else>
        <v-select
          class="mt-2"
          v-model="currentPosition"
          :items="positions"
          outlined
          dense
          item-text="name"
          item-value="index"
          placeholder="Locais do evento"
        ></v-select>
      </template>
    </v-col>
  </v-row>
</template>

<script>
import GLTFViewer from "../global/GLTFViewer.vue";
export default {
  components: { GLTFViewer },
  data: () => ({
    loaded: false,
    loading: 0,
    currentPosition: 0,
    options: {
      controls: {
        // enablePan: false,
      },
      scene: {
        background: {
          type: "color",
          color: "#5e5ce6",
        },
      },
    },
  }),
  watch: {
    currentPosition(val) {
      if (val === null) return;
      if (!this.loaded) {
        if (this.loading) return;
        this.startLoad();
        return;
      }
      const position = this.positions[val];
      this.goToPosition(position.object);
    },
  },
  methods: {
    onLoaded() {
      this.loaded = true;
      this.$nextTick(() => {
        const position = this.positions[this.currentPosition];
        this.goToPosition(position.object);
      });
    },
    startLoad() {
      var type = this.map.url.includes("zip") ? "zip" : "gltf";

      this.$refs.gltf.load({
        type: type,
        url: this.map.url,
      });
    },
    goToPosition(position) {
      this.$refs.gltf.camera.position.set(
        position.position.x,
        position.position.y,
        position.position.z
      );
      this.$refs.gltf.camera.rotation.set(
        position.rotation.x,
        position.rotation.y,
        position.rotation.z
      );
      this.$refs.gltf.controls.target.set(
        position.target.x,
        position.target.y,
        position.target.z
      );
    },
  },
  computed: {
    defaultPosition() {
      return this.map.Positions.find((p) => p.default);
    },
    positions() {
      return this.map.Positions.sort((a, b) => b.default - a.default).map(
        (p, i) => ({
          ...p,
          index: i,
        })
      );
    },
  },
  props: {
    map: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>