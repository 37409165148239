<template>
  <div class="pa-4 py-6">
    <ColsOrganizer :items="organization.Membership" sm="2" md="3" xl="4">
      <template v-slot="{ item: membership }">
        <organization-membership-card :membership="membership" />
      </template>
    </ColsOrganizer>
    <v-alert type="info" class="mt-4 mb-0" prominent text>
      <div class="d-flex justify-space-between align-center flex-wrap">
        <div>
          <p class="mb-0 font-weight-bold">Quer se tornar um sócio?</p>
          <p class="mb-0">Entre em contato com a organização para se filiar</p>
        </div>
        <v-btn color="primary" @click="openWhatsapp">
          <v-icon left>mdi-whatsapp</v-icon>
          Entrar em contato
        </v-btn>
      </div>
    </v-alert>
  </div>
</template>

<script>
import ColsOrganizer from "@/components/global/ColsOrganizer.vue";
import OrganizationMembershipCard from "./OrganizationMembershipCard.vue";

export default {
  components: { ColsOrganizer, OrganizationMembershipCard },
  methods: {
    openWhatsapp() {
      const phone = this.organization.phone.replace(/\D/g, "");
      const ddi = this.organization.ddi;
      window.open(`https://wa.me/${ddi}${phone}?text=Olá, gostaria de me tornar um sócio da ${this.organization.name}`);
    },
  },
  props: {
    organization: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style></style>
