<template>
  <v-dialog v-model="dialog" max-width="500" @click:outside="close">
    <v-card>
      <v-card-text class="pa-6 pb-1" v-if="ticketBlock && ticketGroup">
        <div class="d-flex gap-x-2">
          <div>
            <v-img
              class="rounded"
              :src="party.cover"
              :aspect-ratio="16 / 9"
              height="100px"
              :width="(100 * 16) / 9"
            ></v-img>
          </div>
          <div>
            <h6>{{ party.name }}</h6>
            <div class="d-flex flex-wrap gap-x-4 justify-space-between">
              <span class="text--secondary">
                {{ ticketGroup.name }}
              </span>
              <span class="text--secondary">
                {{ ticketBlock.name }}
              </span>
            </div>
          </div>
        </div>
        <div>
          <v-list>
            <v-list-item dense>
              <h5 class="mb-0">Tabela de Preços</h5>
            </v-list-item>
            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title class="text-16 d-flex gap-2 align-center">
                  <span>Ingresso</span>
                  <v-divider></v-divider>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <h6 class="mb-0">
                  {{ ticketBlock.price | currency }}
                </h6>
              </v-list-item-action>
            </v-list-item>
            <v-list-item
              v-for="(membershipPrice, i) in ticketBlock?.membershipPrice || []"
              :key="i"
              class="pl-0"
              dense
            >
              <v-list-item-content>
                <div class="d-flex align-center gap-3">
                  <v-chip
                    :color="membershipPrice.Membership.backgroundColor"
                    small
                    text-color="white"
                    label
                    class="font-weight-medium text-14 ml-4"
                    :style="{
                      background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${
                        membershipPrice.Membership.backgroundImg
                      }), ${
                        membershipPrice.Membership.backgroundColor || '#3333'
                      }80`,
                      backgroundSize: 'cover',
                      border: `1px solid ${
                        membershipPrice.Membership.backgroundColor || '#000'
                      }`,
                      boxShadow: `0 0 10px  ${
                        membershipPrice.Membership.backgroundColor ||
                        'rgba(0,0,0,.2)'
                      }`,
                    }"
                  >
                    {{ membershipPrice.Membership.name }}
                  </v-chip>
                  <v-divider horizontal class="flex-grow-1"></v-divider>
                </div>
              </v-list-item-content>
              <v-list-item-action>
                <h6 class="mb-0">
                  {{ membershipPrice.price | currency }}
                </h6>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </div>

        <v-divider class="mb-4"></v-divider>

        <template v-if="!user">
          <v-alert color="info" dense text>
            Entre na sua conta para ver o preço com desconto
          </v-alert>
          <v-alert color="success" dense text>
            O desconto será aplicado automaticamente caso tenha o benefício
          </v-alert>
        </template>
        <template v-else>
          <!-- Usuário logado -->
          <v-card
            class="d-flex flex-column justify-center gap-0 align-center pa-4"
            outlined
          >
            <span class="text-overline">Preço para você</span>
            <h3 class="mb-0">
              {{
                ticketBlock?.membershipPrice[0].canUse
                  ? ticketBlock.membershipPrice[0].price
                  : ticketBlock.price | currency(true)
              }}
            </h3>
            <template v-if="ticketBlock?.membershipPrice[0].canUse">
              <span class="font-weight-medium text-14">
                Você economiza
                {{
                  (ticketBlock.price - ticketBlock.membershipPrice[0].price)
                    | currency(true)
                }}
              </span>
              <span class="text--secondary text-11">
                Limite de apenas um ingresso com desconto
              </span>
            </template>
          </v-card>
          <template v-if="!ticketBlock?.membershipPrice[0].canUse">
            <v-alert
              v-if="ticketBlock?.membershipPrice[0].alreadyUsed"
              color="error"
              dense
              text
              class="mt-4 mb-0"
            >
              Você já usou seu desconto para comprar um ingresso para esse
              evento
            </v-alert>
            <v-alert
              v-else
              type="info"
              icon="mdi-card-account-details-star"
              class="mt-4 mb-0"
              text
            >
              Você não tem desconto para esse evento. Torne-se um membro para
              ter desconto e outros benefícios
              <v-btn color="primary" class="mt-1" block @click="goToMembership">
                Saiba mais
              </v-btn>
            </v-alert>
          </template>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close">Fechar</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => ({
    dialog: false,
    ticketBlock: false,
    ticketGroup: false,
  }),
  methods: {
    goToMembership() {
      this.$router.push({
        name: "shop.organization",
        params: { orgSlug: this.$route.params.orgSlug },
        hash: "#memberships",
      });
    },
    open({ ticketBlock, ticketGroup }) {
      this.dialog = true;
      this.ticketBlock = ticketBlock;
      this.ticketGroup = ticketGroup;
    },
    close() {
      this.dialog = false;
    },
  },
  mounted() {
    this.$root.$on("ticket-block-membership-price", this.open);
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
