<template>
  <div>
    <v-slide-y-transition leave-absolute v-if="loading || !!error">
      <v-card :loading="loading">
        <v-alert type="info" class="mb-0" text border="left" v-if="loading">
          Carregando...
        </v-alert>
        <v-alert v-if="!!error" type="error" class="my-2" text border="bottom">
          <div class="font-weight-bold">
            Ocorreu um erro ao carregar a organização
          </div>
          <div>{{ error }}</div>
        </v-alert>
      </v-card>
    </v-slide-y-transition>
    <template v-else-if="data">
      <organization-profile-header :organization="data?.organization" />

      <v-card class="mt-4 rounded-lg">
        <v-tabs grow v-model="tab" class="rounded-t-lg">
          <v-tab v-for="item in filteredTabs" :key="item.component">
            <v-icon left>{{ item.icon }}</v-icon>
            {{ item.label }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in filteredTabs" :key="item.component">
            <component :is="item.component" :organization="data.organization" />
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </template>
  </div>
</template>

<script>
import SHOP from "@/services/shop";
import { mapGetters } from "vuex";

import OrganizationProfileHeader from "../../components/shop/organization/OrganizationProfileHeader.vue";
import OrganizationParties from "../../components/shop/organization/OrganizationParties.vue";
import OrganizationMemberships from "../../components/shop/organization/OrganizationMemberships.vue";
import OrganizationMembershipPartners from "../../components/shop/organization/OrganizationMembershipPartners.vue";

export default {
  components: {
    OrganizationProfileHeader,
    OrganizationParties,
    OrganizationMemberships,
    OrganizationMembershipPartners,
  },
  metaInfo() {
    return { title: this.data?.organization.name || "Organização" };
  },
  data: () => ({
    loading: false,
    error: null,
    data: null,
    tab: null,

    tabsItens: [
      {
        id: "parties",
        component: "organization-parties",
        label: "Eventos",
        icon: "mdi-calendar",
      },
      {
        id: "memberships",
        component: "organization-memberships",
        label: "Sócios",
        icon: "mdi-card-account-details-star",
        rule: (data) => data?.organization?.Membership?.length,
      },
      {
        id: "partners",
        component: "organization-membership-partners",
        label: "Parceiros",
        icon: "mdi-account-group",
        rule: (data) => data?.organization?.MembershipPartner?.length,
      },
    ],
  }),
  methods: {
    async getData() {
      try {
        this.loading = true;
        this.error = null;
        let response = await SHOP.organization(this.$route.params.orgSlug);
        this.data = response;
        this.selectTab();
        // this.$root.$emit("setImgBg", this.data?.organization.cover);
      } catch (error) {
        this.error = error.message || "Tente novamente mais tarde";
      } finally {
        this.loading = false;
      }
    },
    selectTab() {
      if (this.$route.hash) {
        let hash = this.$route.hash.replace("#", "");
        let index = this.filteredTabs.findIndex((tab) => tab.id == hash);
        if (index) this.tab = index;
      }
    },
    vibrate() {
      if (navigator.vibrate) navigator.vibrate(50);
    },
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
    filteredTabs() {
      return this.tabsItens.filter((item) => {
        if (!item.rule) return true;
        return item.rule(this.data);
      });
    },
  },
  watch: {
    tab: {
      handler(val, oldVal) {
        if (val !== null && oldVal !== null) this.vibrate();
        let tab = this.filteredTabs[val];
        if (tab) window.location.replace("#" + tab.id);
      },
      immediate: true,
    },
    "$route.hash"() {
      this.selectTab();
    },
    "$route.params.orgSlug"() {
      this.getData();
    },
  },

  mounted() {
    this.getData();
  },
};
</script>

<style></style>
