<template>
  <div class="pa-4 py-6">
    <v-alert type="info" class="" prominent text>
      <div class="font-weight-bold">Parceiros</div>
      <div>
        Os parceiros são empresas que oferecem descontos e vantagens para os
        sócios da organização
      </div>
    </v-alert>
    <!-- <v-text-field dense hide-details label="Pesquisar" v-model="search" outlined /> -->
    <partners :partners="organization.MembershipPartner" :search="search" showSearch />
  </div>
</template>

<script>
import ColsOrganizer from "@/components/global/ColsOrganizer.vue";
import PartnerCard from "../../app/membership/PartnerCard.vue";
import Partners from "../../app/membership/Partners.vue";

export default {
  data: () => ({
    search: "",
  }),
  components: { ColsOrganizer, PartnerCard, Partners },
  props: {
    organization: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style></style>
