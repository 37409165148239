<template>
  <div id="ticket-group-selector">
    <div class="mb-2 d-flex align-center justify-space-between">
      <h5 class="mb-0">
        Comprar ingresso{{ party.maxTickets != 1 ? "s" : "" }}
      </h5>

      <v-btn
        v-if="party.maxTickets != 1"
        small
        :disabled="!cartTotal.quantity"
        @click="checkout"
        :text="!cartTotal.quantity"
        :color="'primary'"
      >
        <v-icon left small>mdi-cart</v-icon>
        {{ cartTotal.quantity }}
        ingresso{{ cartTotal.quantity != 1 ? "s" : "" }} •
        {{ cartTotal.total | currency(true) }}
      </v-btn>
    </div>
    <template v-if="ticketGroups.length">
      <v-card
        v-if="seller"
        outlined
        elevation="0"
        rounded="lg"
        class="pa-2 d-flex gap-2 mx-2 mb-3"
      >
        <baseAvatar
          :size="40"
          :src="seller.photo"
          :seed="seller.id"
        ></baseAvatar>
        <div>
          <p class="text-overline lh-1 mb-0">Promoter</p>
          <p class="mb-0 font-weight-bold">{{ seller.name | firstName }}</p>
        </div>
      </v-card>

      <v-expansion-panels focusable class="px-2" v-model="ticketGroupPanel">
        <v-expansion-panel
          v-for="(tg, i) in ticketGroupsSorted"
          :key="tg.id"
          :id="`ticket-group-${tg.id}`"
        >
          <v-expansion-panel-header>
            <div class="d-flex align-center gap-x-4 gap-y-1 flex-wrap">
              <div>
                <v-badge
                  inline
                  :content="ticketsPerGroup[tg.id]?.cart"
                  :value="!!ticketsPerGroup[tg.id]?.cart"
                >
                  <b class="text-16">{{ tg.name }}</b>
                </v-badge>
              </div>
              <v-scroll-y-transition>
                <div v-if="i != ticketGroupPanel">
                  <v-chip v-if="tg.TableGroup" color="primary" x-small>
                    <v-icon x-small left>
                      mdi-account{{
                        tg.TableGroup.capacity > 1 ? "-group" : ""
                      }}
                    </v-icon>
                    {{ tg.TableGroup.capacity }} pessoa{{
                      tg.TableGroup.capacity > 1 ? "s" : ""
                    }}
                  </v-chip>
                  <v-chip color="primary" v-if="tg.exclusive" x-small>
                    <v-icon x-small left> mdi-star-four-points </v-icon>
                    Exclusivo
                  </v-chip>
                  <v-chip
                    color="primary"
                    v-else-if="
                      periods.length && tg.periods.every((p) => p.access)
                    "
                    x-small
                  >
                    <v-icon x-small left> mdi-star-four-points </v-icon>
                    Acesso completo
                  </v-chip>
                </div>
              </v-scroll-y-transition>
            </div>
            <v-scroll-y-transition leave-absolute>
              <div v-if="i != ticketGroupPanel" class="flex-grow-0">
                <span
                  v-if="tg.lowestPrice"
                  class="text-end text--disabled pr-4"
                >
                  A partir de
                  {{ tg.lowestPrice | currency(true) }}
                </span>
                <span
                  v-else-if="tg.pending"
                  class="text-end text--disabled pr-4"
                >
                  Em breve
                </span>
                <span v-else class="text-end text--disabled pr-4">
                  Esgotado
                </span>
              </div>
            </v-scroll-y-transition>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-alert
              v-if="tg.requireBiometry"
              text
              dense
              color="info"
              icon="mdi-face-recognition"
              class="mt-2"
            >
              O acesso a esse setor é validado por reconhecimento facial.
              {{
                hasFace
                  ? "Seu cadastro já está pronto para uso."
                  : "Realize o cadastro do seu rosto após a compra."
              }}
            </v-alert>
            <v-alert
              type="info"
              text
              dense
              color="primary"
              class="mt-2"
              v-if="tg.TableGroup"
            >
              <p class="mb-0">
                Os ingressos de setor são vendidos para
                <b class="font-weight-bold">
                  {{ tg.TableGroup.name }}s com lugar marcado
                </b>
                e
                <b class="font-weight-bold">
                  dão direito a {{ tg.TableGroup.capacity }} ingresso{{
                    tg.TableGroup.capacity > 1 ? "s" : ""
                  }}.</b
                >
              </p>
              <p class="mb-0">
                {{ tg.TableGroup.description }}
              </p>
            </v-alert>
            <div class="mt-2" v-if="tg.description">
              <p class="font-weight-bold mb-0">Descrição:</p>
              {{ tg.description }}
            </div>
            <v-divider class="my-2" v-if="tg.description" />
            <div v-if="periods.length">
              <div class="d-flex gap-x-4 gap-y-1 flex-wrap align-center">
                <p class="font-weight-bold mb-0">Períodos com acesso:</p>
                <v-chip
                  color="primary"
                  v-if="tg.periods.every((p) => p.access)"
                  x-small
                >
                  <v-icon x-small left> mdi-star-four-points </v-icon>
                  Acesso completo
                </v-chip>
              </div>

              <v-chip-group>
                <v-tooltip v-for="period in tg.periods" :key="period.id" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      v-on="on"
                      small
                      :color="period.access ? 'success' : 'warning'"
                      label
                      @click="scrollToPeriod(period.index)"
                      v-bind="attrs"
                    >
                      <span>
                        <v-icon x-small left>
                          {{ !period.access ? "mdi-lock" : "mdi-check" }}
                        </v-icon>
                        {{ period.name || `Período ${period.index + 1}` }}
                      </span>
                    </v-chip>
                  </template>
                  <span>
                    {{ period.access ? "Acesso liberado" : "Sem acesso" }}
                  </span>
                </v-tooltip>
              </v-chip-group>
            </div>
            <v-list>
              <v-list-item dense>
                <h6 class="mb-0">Lotes</h6>
              </v-list-item>
              <v-list-item
                class="border-1"
                v-for="tb in tg.ticketBlocks"
                :key="tb.id"
              >
                <v-list-item-content>
                  <div
                    class="d-flex justify-space-between gap-x-2 gap-y-1 flex-wrap"
                  >
                    <div>
                      <p class="mb-1 text-16 lh-5 font-weight-medium">
                        {{ tb.name }}
                      </p>
                      <div class="d-flex align-center">
                        <b v-if="tb.status === 'available'">
                          <div
                            class="d-flex align-center gap-x-2 gap-y-1 flex-wrap"
                          >
                            <div class="d-flex flex-column">
                              <span
                                :class="{
                                  'text-decoration-line-through':
                                    tb.canUseMembership,
                                  'font-weight-medium': tb.canUseMembership,
                                  'text-16': tb.canUseMembership,
                                  'text-18': !tb.canUseMembership,
                                  'text--disabled': tb.canUseMembership,
                                }"
                              >
                                {{ tb.price | currency(true) }}
                              </span>
                              <small
                                v-if="tb.fee"
                                class="text--secondary font-weight-medium"
                              >
                                Taxa: +{{ tb.fee | currency(true) }}
                              </small>
                            </div>

                            <v-chip v-if="!!tg?.TableGroup" label small>
                              {{ tg.TableGroup.capacity }}
                              <!-- pessoa{{
                                tg.TableGroup.capacity > 1 ? "s" : ""
                              }} -->x
                              <!-- <v-icon small right left>
                                mdi-account{{
                                  tg.TableGroup.capacity > 1 ? "-group" : ""
                                }}
                              </v-icon> -->
                              <v-icon small right left>
                                mdi-ticket-account
                              </v-icon>
                            </v-chip>

                            {{ tb.canUse }}

                            <template v-if="tb.alreadyUsed">
                              <v-chip
                                color="secondary"
                                small
                                label
                                @click="showPriceModal({ tb, tg })"
                              >
                                <v-icon x-small left>
                                  mdi-card-account-details-star
                                </v-icon>
                                Desconto já utilizado
                              </v-chip>
                            </template>

                            <template v-else-if="tb.canUseMembership">
                              <b class="font-weight-bold text-18 ml-2">
                                {{
                                  tb.membershipPrice[0].price | currency(true)
                                }}
                              </b>

                              <v-chip
                                :color="
                                  tb.membershipPrice[0].Membership
                                    .backgroundColor
                                "
                                small
                                text-color="white"
                                label
                                class="font-weight-medium text-14"
                                :style="{
                                  background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${
                                    tb.membershipPrice[0].Membership
                                      .backgroundImg
                                  }), ${
                                    tb.membershipPrice[0].Membership
                                      .backgroundColor || '#3333'
                                  }80`,
                                  backgroundSize: 'cover',
                                  border: `1px solid ${
                                    tb.membershipPrice[0].Membership
                                      .backgroundColor || '#000'
                                  }`,
                                  boxShadow: `0 0 10px  ${
                                    tb.membershipPrice[0].Membership
                                      .backgroundColor || 'rgba(0,0,0,.2)'
                                  }`,
                                }"
                                @click="showPriceModal({ tb, tg })"
                              >
                                <span
                                  :class="{
                                    'text-decoration-line-through':
                                      tb.alreadyUsed,
                                  }"
                                >
                                  {{ tb.membershipPrice[0].Membership.name }}
                                </span>
                                <v-icon v-if="tb.alreadyUsed" right>
                                  mdi-check
                                </v-icon>
                              </v-chip>
                            </template>

                            <template
                              v-else-if="
                                tb.membershipPrice && tb.membershipPrice.length
                              "
                            >
                              <v-chip
                                color="primary"
                                x-small
                                icon="mdi-card-account-details-star"
                                @click="showPriceModal({ tb, tg })"
                              >
                                {{
                                  tb.membershipPrice.length > 1
                                    ? "A partir de"
                                    : ""
                                }}
                                <b class="font-weight-bold text-12 mx-1">
                                  {{
                                    tb.membershipPrice[0].price | currency(true)
                                  }}
                                </b>

                                para
                                <b class="font-weight-bold text-12 mx-1">
                                  {{
                                    tb.membershipPrice.length > 1
                                      ? `socios`
                                      : tb.membershipPrice[0].Membership.name
                                  }}
                                </b>

                                <v-icon right small> mdi-chevron-down </v-icon>
                              </v-chip>
                              <!-- <span
                                class="font-weight-medium text-16 text--disabled ml-2"
                              >
                                Saiba Mais
                              </span> -->
                            </template>
                          </div>
                        </b>
                        <span v-else-if="tb.status === 'sold-out'">
                          Esgotado
                        </span>
                        <span v-else-if="tb.status === 'pending'">
                          Em breve
                        </span>
                      </div>
                    </div>
                    <template v-if="tb.status == 'available'">
                      <template v-if="tg.TableGroup">
                        <v-btn
                          v-if="
                            party.maxTickets === 1 || !cart[tb.id]?.quantity
                          "
                          color="primary"
                          :loading="loading === tb.id"
                          @click="selectTable(tb, tg)"
                          :disabled="
                            !!party.maxTickets &&
                            (ticketsPerGroup[tg.id]?.total >=
                              Math.min(party.maxTickets, tg.maxTickets) ||
                              partyTotal >= party.maxTickets)
                          "
                        >
                          Selecionar Lugar
                        </v-btn>
                        <v-card
                          v-else
                          outlined
                          class="d-flex align-center gap-2 rounded-pill pa-1 align-self-center"
                        >
                          <v-btn icon @click="removeItem(tb.id)" small>
                            <v-icon>mdi-minus</v-icon>
                          </v-btn>
                          <b class="text-center">
                            {{ cart[tb.id].quantity }}
                          </b>
                          <v-btn
                            icon
                            @click="selectTable(tb, tg)"
                            small
                            :disabled="
                              !!party.maxTickets &&
                              (ticketsPerGroup[tg.id]?.total >=
                                Math.min(party.maxTickets, tg.maxTickets) ||
                                partyTotal >= party.maxTickets)
                            "
                          >
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                        </v-card>
                      </template>
                      <template v-else>
                        <v-btn
                          v-if="
                            party.maxTickets === 1 || !cart[tb.id]?.quantity
                          "
                          color="primary"
                          :loading="loading === tb.id"
                          @click="
                            buyTicket({ ticketGroup: tg, ticketBlock: tb })
                          "
                          :disabled="
                            !!party.maxTickets &&
                            (ticketsPerGroup[tg.id]?.total >=
                              Math.min(party.maxTickets, tg.maxTickets) ||
                              partyTotal >= party.maxTickets)
                          "
                        >
                          {{ party.maxTickets === 1 ? "Comprar" : "Adicionar" }}
                        </v-btn>
                        <v-card
                          v-else
                          outlined
                          class="d-flex align-center gap-2 rounded-pill pa-1 align-self-center"
                        >
                          <v-btn icon @click="cart[tb.id].quantity--" small>
                            <v-icon>mdi-minus</v-icon>
                          </v-btn>
                          <b class="text-center">
                            {{ cart[tb.id].quantity }}
                          </b>
                          <v-btn
                            icon
                            @click="
                              buyTicket({ ticketGroup: tg, ticketBlock: tb })
                            "
                            small
                            :disabled="
                              !!party.maxTickets &&
                              (ticketsPerGroup[tg.id]?.total >=
                                Math.min(party.maxTickets, tg.maxTickets) ||
                                partyTotal >= party.maxTickets)
                            "
                          >
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                        </v-card>
                      </template>
                    </template>
                  </div>

                  <!-- <v-list-item-title>{{ tb.name }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <span v-if="tb.status === 'available'">
                      <b>{{ tb.price | currency }}</b>
                    </span>
                    <span v-else-if="tb.status === 'sold-out'"> Esgotado </span>
                    <span v-else-if="tb.status === 'pending'"> Em breve </span>
                  </v-list-item-subtitle> -->
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <p
              v-if="!!party.maxTickets"
              class="mb-0 text-center text-12 text--secondary"
            >
              Limite de
              {{ Math.min(party.maxTickets, tg.maxTickets) }} ingresso{{
                Math.min(party.maxTickets, tg.maxTickets) != 1 ? "s" : ""
              }}
              por pessoa para esse setor.
            </p>
            <p
              v-if="ticketsPerGroup[tg.id]?.user"
              class="mb-0 text-center text-12 text--disabled"
            >
              Você já possui
              {{ ticketsPerGroup[tg.id]?.user }} ingresso{{
                ticketsPerGroup[tg.id]?.user != 1 ? "s" : ""
              }}
              para esse setor.
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <accept-payment-methods class="mx-1 mt-2" />
    </template>
    <template v-else>
      <div class="text-center">
        <v-avatar color="grey lighten-4" size="50">
          <v-icon x-large class="display-1 grey darken-1">mdi-ticket</v-icon>
        </v-avatar>
        <p class="text--disabled mt-2">
          Não há ingressos disponíveis para esse evento
        </p>
      </div>
    </template>

    <v-slide-y-reverse-transition>
      <div
        v-if="cartTotal.quantity"
        style="position: fixed; bottom: 0px; left: 0px; right: 0px; z-index: 10"
        class="d-flex justify-center pa-3 mb-16 mb-md-0"
      >
        <v-card
          elevation="24"
          class="d-flex align-center pa-3 gap-4 flex-grow-"
          rounded="xl"
          light
        >
          <v-icon>mdi-cart</v-icon>
          <span class="text-16 font-weight-medium flex-grow-1">
            {{ cartTotal.quantity }}
            ingresso{{ cartTotal.quantity != 1 ? "s" : "" }} •
            {{ cartTotal.total | currency(true) }}
          </span>
          <v-btn color="primary" @click="checkout"> Finalizar Compra </v-btn>
        </v-card>
      </div>
    </v-slide-y-reverse-transition>

    <select-table
      v-if="tableMap"
      :party="party"
      :cart="cart"
      :table-map="tableMap"
      @select="buyTicket"
    />

    <ticket-block-membership-price :party="party" />
    <require-sign-in @success="successSign" />
    <buy-ticket :party="party" :seller="seller" @success="refresh" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AcceptPaymentMethods from "../global/AcceptPaymentMethods.vue";
import BuyTicket from "./modal/BuyTicket.vue";
import RequireSignIn from "./modal/RequireSignIn.vue";
import SelectTable from "./modal/SelectTable.vue";
import TicketBlockMembershipPrice from "./modal/TicketBlockMembershipPrice.vue";
import { process } from "@/utils/shop/cart";

export default {
  components: {
    RequireSignIn,
    BuyTicket,
    AcceptPaymentMethods,
    TicketBlockMembershipPrice,
    SelectTable,
  },
  data: () => ({
    loading: false,
    ticketGroupPanel: 0,
    cart: {},
    statusOrder: ["sold-out", "available", "pending"],
  }),
  methods: {
    removeItem(id) {
      if (this.cart[id].quantity > 0) {
        this.cart[id].quantity--;
        this.cart[id].tables.pop();
      }
    },
    showPriceModal({ tb, tg }) {
      this.$root.$emit("ticket-block-membership-price", {
        ticketBlock: tb,
        ticketGroup: tg,
      });
    },
    refresh() {
      this.$emit("refresh");
    },
    selectTable(ticketBlock, ticketGroup) {
      this.$root.$emit("select-table", { ticketBlock, ticketGroup });
    },
    successSign() {
      this.refresh();
    },
    promiseSignIn() {
      const promise = new Promise((resolve, reject) => {
        this.promiseSignInData = { resolve, reject };
      });
      return promise;
    },
    async buyTicket({ ticketBlock, ticketGroup, table }) {
      try {
        if (!this.isAuthenticated) {
          this.$root.$emit("require-sign-in");
          return;
        }

        if (this.party.maxTickets === 1)
          return this.$root.$emit("buy-ticket", {
            code: this.code,
            cart: {
              [ticketBlock.id]: {
                ticketBlock,
                ticketGroup,
                tables: table ? [table] : [],
                quantity: 1,
              },
            },
          });

        if (!this.cart[ticketBlock.id]) {
          const data = {
            ticketBlock,
            ticketGroup,
            tables: [],
            quantity: 0,
          };
          this.$set(this.cart, ticketBlock.id, data);
        }

        const currentTicketBlock = this.cart[ticketBlock.id];

        this.$set(this.cart, ticketBlock.id, {
          ...currentTicketBlock,
          tables: table
            ? [...currentTicketBlock.tables, table]
            : currentTicketBlock.tables,
          quantity: currentTicketBlock.quantity + 1,
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async checkout() {
      try {
        this.loading = true;
        if (!this.isAuthenticated) {
          this.$root.$emit("require-sign-in");
          return;
        }
        this.$root.$emit("buy-ticket", {
          code: this.code,
          cart: Object.assign({}, this.cart),
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    scrollToPeriod(index) {
      var el = document.getElementById(`period-${index + 1}`);
      if (index < 1) el = document.getElementById("periods");
      if (el)
        this.$vuetify.goTo(el, {
          offset: 30,
          behavior: "smooth",
        });
    },
    calculateFee(ticketGroup, ticketBlock) {
      if (ticketBlock.status !== "available") return 0;
      if (this.fees.length === 0) return 0;

      const fee = this.fees.reduce((acc, fee) => {
        // ticketBlockId is the most specific
        if (acc?.ticketBlockId) return acc;
        if (fee.ticketBlockId && fee.ticketBlockId !== ticketBlock.id)
          return acc;

        // ticketGroupId is the second most specific
        if (acc?.ticketGroupId) return acc;
        if (fee.ticketGroupId && fee.ticketGroupId !== ticketGroup.id)
          return acc;

        return fee;
      }, null);
      if (!fee) return 0;

      const { fixed, percent } = fee.outerFee;
      const ticketPrice = ticketBlock.price;
      return fixed + (ticketPrice + fixed) * (percent / 100);
    },
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated", "user"]),
    hasFace() {
      return (
        !!this.isAuthenticated &&
        (this.user?.Biometrics || []).some(({ type }) => type === "FACE")
      );
    },
    partyTotal() {
      return Object.values(this.ticketsPerGroup).reduce(
        (acc, { total }) => acc + total,
        0
      );
    },
    ticketsPerGroup() {
      if (!this.tickets) return {};
      const userTickets = this.tickets.reduce((acc, ticket) => {
        const ticketGroupId = ticket.TicketBlock.TicketGroup.id;
        if (!acc[ticketGroupId])
          acc[ticketGroupId] = { cart: 0, user: 0, total: 0 };
        acc[ticketGroupId].user += 1;
        acc[ticketGroupId].total += 1;
        return acc;
      }, {});

      return Object.values(this.cart).reduce(
        (acc, { quantity, ticketGroup }) => {
          if (!acc[ticketGroup.id])
            acc[ticketGroup.id] = { cart: 0, user: 0, total: 0 };
          acc[ticketGroup.id].cart += quantity;
          acc[ticketGroup.id].total += quantity;
          return acc;
        },
        userTickets
      );
    },

    cartTotal() {
      return this.cartProcessed.totalValue;
    },
    cartProcessed() {
      return process(this.cart);
    },
    ticketGroupsSorted() {
      return this.ticketGroups.map((tg) => {
        return {
          ...tg,
          periods: this.periods.map((p, index) => ({
            ...p,
            index,
            access: p.TicketGroup.some(({ id }) => id === tg.id),
          })),
          TableGroup: this.tableMap?.Groups.find(
            ({ id }) => id === tg.tableGroupId
          ),

          ticketBlocks: tg.ticketBlocks
            .map((tb) => {
              return {
                ...tb,
                fee: this.calculateFee(tg, tb),
                membershipPrice: tb.membershipPrice.sort((a, b) => {
                  if (a.canUse && b.canUse) return a.price - b.price;
                  if (a.canUse) return -1;
                  if (b.canUse) return 1;
                  return a.price - b.price;
                }),
                canUseMembership: (tb.membershipPrice || []).some(
                  ({ canUse }) => canUse
                ),
                alreadyUsed: (tb.membershipPrice || []).some(
                  ({ alreadyUsed }) => alreadyUsed
                ),
              };
            })
            .sort((a, b) => {
              const aStatus = this.statusOrder.indexOf(a.status);
              const bStatus = this.statusOrder.indexOf(b.status);
              if (aStatus === bStatus) {
                return a.name.localeCompare(b.name);
              }
              return aStatus - bStatus;
            }),

          pending: tg.ticketBlocks.some((tb) => tb.status === "pending"),
          lowestPrice:
            tg.ticketBlocks
              .filter((tb) => tb.status === "available")
              .sort((a, b) => a.price - b.price)[0]?.price || null,
        };
      });
    },
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
    periods: {
      type: Array,
      default: () => [],
    },
    tickets: {
      type: Array,
      default: () => [],
    },
    ticketGroups: {
      type: Array,
      required: true,
    },
    code: {
      type: String,
    },
    tableMap: {
      type: Object,
      default: () => ({}),
    },
    fees: {
      type: Array,
      default: () => [],
    },
    seller: {
      type: Object,
      default: () => null,
    },
  },
  mounted() {
    this.$root.$on("select-ticket-group", (ticketGroupId) => {
      const index = this.ticketGroups.findIndex(
        (tg) => tg.id === ticketGroupId
      );
      if (index > -1) this.ticketGroupPanel = index;
    });
  },
};
</script>

<style></style>
